import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HomeHeader, BannerCenter, BannerButton } from "../utils"
import img from "../images/bcg/lakeside-patio-restaurant-in-clemson-sc.jpg"
import food from "../images/bcg/scallops.jpg"
import QuickInfo from "../components/HomePageComponents/QuickInfo"

const IndexPage = () => (
  <Layout>
    <SEO 
      title="Home"
      img={img}
    />
    <HomeHeader img={img}>
      <BannerCenter
        title="On Lake Hartwell"
        subtitle="Lakeside Restaurant & Bar"
      >
        <BannerButton>
          <Link to="/menu/">The Menus</Link>
        </BannerButton>
        <br />
        <br />
        <BannerButton>
          <a href="tel:864-722-2800" rel="noopener noreferrer">864-722-2800</a>
        </BannerButton>
      </BannerCenter>
    </HomeHeader>
    <QuickInfo></QuickInfo>
    <HomeHeader img={food}>
    </HomeHeader>
  </Layout>
)

export default IndexPage
